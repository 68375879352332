<template>
  <div class="page flexc">服务正在升级维护中。。。</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.page {
  height: 100vh;
  background-color: #fff;
}
</style>
